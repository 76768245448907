<template>
    
    <div class="box mt-2 relative">
        <loader :loading="loading" />
        <div class="box-title">TNR</div>
        <div class="flex">
            <div class="w-full overflow-hidden"></div>
            <div class="text-2xl text-center font-bold flex-none">{{ 28.12 | numberFormat(2) }}%</div>
            <div class="w-full overflow-hidden flex justify-end">
                <router-link class="header-btn" :to="'Tnr'">
                    <i class="mdi mdi-ray-start-arrow"></i>
                </router-link>
            </div>
        </div>
    </div>

</template>

<script>

import { state } from '@/store';

export default {
    data() {
        return {
            loading: false,
            ner: false
        }
    },
    methods: {
        load() {
            this.loading = true
            // this.axios.get('projects_management/ner',{params:this.params}).then(response => {
            //     this.loading = false
            // })
        }
    },
    computed:{
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        }
    },
    watch: {
        params() { this.load() }
    },
    mounted() { this.load() }
}

</script>